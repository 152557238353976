import Country, { CountryJSON } from '@/store/models/country';
import Image, { ImageJSON } from '@/store/models/image';
import { JSONModel, toJSONContext } from '@/store/models/jsonModel';
import Taxonomy, { TaxonomyJSON } from '@/store/models/taxonomy';
import { PublishStatus } from '@/store/types';
import ResourceFile, { ResourceFileJSON } from './file';
import ResourceLink, { ResourceLinkJSON } from './link';

export interface ResourceJSON {
  id: number;
  language_id: number;
  language_code: string;
  status: number;
  title: string;
  nid: number;
  description: string;
  video: string;
  log?: string;
  url: string;
  store_url?: string;
  store_text?: string;
  rating: number;
  // resource details
  files_count?: number;
  links_count?: number;
  thumbnail?: ImageJSON;
  preview?: string[];
  categories: TaxonomyJSON[];
  products: TaxonomyJSON[];
  all_products: 0 | 1;
  all_treatments: 0 | 1;
  treatments: TaxonomyJSON[];
  files: ResourceFileJSON[];
  links: ResourceLinkJSON[];
  zip: string;
  featured: 0 | 1;
  training: 0 | 1;
  countries: CountryJSON[];
  personalize: 0 | 1;
  template_type: string;
  personalize_product: string;
  type?: string;
}

export class Resource extends JSONModel<ResourceJSON> {
  public id: number = 0;
  public languageId: number = 0;
  public languageCode: string = '';
  public status: PublishStatus = PublishStatus.Published;
  public title: string = '';
  public nid: number = 0;
  public description: string = '';
  public video: string = '';
  public log?: string;
  public url: string = '';
  public storeUrl: string = '';
  public storeText: string = '';
  public rating: number = 0;
  public filesCount?: number;
  public linksCount?: number;
  public thumbnail?: Image;
  public preview?: string[];
  public categories: Taxonomy[] = [];
  public products: Taxonomy[] = [];
  public allProducts: 0 | 1 = 0;
  public allTreatments: 0 | 1 = 0;
  public treatments: Taxonomy[] = [];
  public files: ResourceFile[];
  public links: ResourceLink[];
  public zip: string = '';
  public featured: 0 | 1 = 0;
  public training: 0 | 1 = 0;
  public countries: Country[] = [];
  public personalize: 0 | 1 = 0;
  public templateType: string = '';
  public personalizeProduct: string = '';
  public type?: string;

  constructor() {
    super();
    this.files = [new ResourceFile()];
    this.links = [new ResourceLink()];
  }

  public fromJSON(json: ResourceJSON) {
    if (json.id) {
      this.id = Number(json.id);
    }

    if (json.language_id) {
      this.languageId = Number(json.language_id);
    }

    if (json.language_code) {
      this.languageCode = String(json.language_code);
    }
    if (json.status || json.status === 0) {
      this.status = Number(json.status);
    }

    if (json.title) {
      this.title = String(json.title);
    }

    if (json.nid) {
      this.nid = Number(json.nid);
    }

    if (json.description) {
      this.description = String(json.description);
    }

    if (json.video) {
      this.video = String(json.video);
    }

    if (json.log) {
      this.log = String(json.log);
    }

    if (json.url) {
      this.url = String(json.url);
    }

    if (json.store_url) {
      this.storeUrl = String(json.store_url);
    }

    if (json.store_text) {
      this.storeText = String(json.store_text);
    }

    if (json.type) {
      this.type = String(json.type);
    }

    if (json.rating) {
      this.rating = Number(json.rating);
    }

    if (json.files_count) {
      this.filesCount = Number(json.files_count);
    }

    if (json.links_count) {
      this.linksCount = Number(json.links_count);
    }

    if (json.thumbnail && !Array.isArray(json.thumbnail)) {
      this.thumbnail = Image.fromJSON(json.thumbnail);
    }

    if (json.preview) {
      this.preview = json.preview;
    }

    if (json.categories && Array.isArray(json.categories)) {
      this.categories = json.categories.map((item) => Taxonomy.fromJSON(item));
    }

    if (json.products && Array.isArray(json.products)) {
      this.products = json.products.map((item) => Taxonomy.fromJSON(item));
    }

    if (+json.all_products === 1) {
      this.allProducts = 1;
    }

    if (json.all_treatments === 1) {
      this.allTreatments = 1;
    }

    if (json.treatments && Array.isArray(json.treatments)) {
      this.treatments = json.treatments.map((item) => Taxonomy.fromJSON(item));
    }

    if (json.files && Array.isArray(json.files)) {
      this.files = json.files.map((item) => ResourceFile.fromJSON(item));
    }

    if (!this.files.length) {
      this.files = [new ResourceFile()];
    }

    if (json.links && Array.isArray(json.links)) {
      this.links = json.links.map((item) => ResourceLink.fromJSON(item));
    }

    if (!this.links.length) {
      this.links = [new ResourceLink()];
    }

    if (json.zip) {
      this.zip = json.zip;
    }

    if (json.featured === 1) {
      this.featured = 1;
    }

    if (json.training === 1) {
      this.training = 1;
    }

    if (json.countries && Array.isArray(json.countries)) {
      this.countries = json.countries.map((item) => Country.fromJSON(item));
    }

    if (json.personalize === 1) {
      this.personalize = 1;
    }
    if (json.template_type) {
      this.templateType = json.template_type;
    }
    if (json.personalize_product) {
      this.personalizeProduct = json.personalize_product;
    }
  }

  public toJSON(context: toJSONContext = 'server') {
    const json = super.toJSON(context);

    return {
      ...json,
      files: json.files.filter((file) => file.id),
      links: json.links.filter((link) => link.title || link.url),
    };
  }
}
